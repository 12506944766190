import React from 'react';

import { ButtonProps } from 'antd/lib/button';
// import { NativeButtonProps, AnchorButtonProps } from 'antd/lib/button/button';

import { ButtonCustomProps } from './interfaces';
import { SBtn } from './styles';

const Button: React.FC<
  ButtonProps & ButtonCustomProps /*  & AnchorButtonProps & NativeButtonProps */
> = props => {
  return <SBtn size="large" {...props} />;
};

export default Button;
