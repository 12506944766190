import React from 'react';

import { CardProps } from './interfaces';
import { SCard, SMeta } from './styles';

const CCard: React.FC<CardProps> = ({
  img,
  alt,
  width,
  title,
  description,
  margin,
  bordered,
  classNameTitle,
  classNameDescription,
  bodyStyle,
  textCover,
}) => {
  const cover = () => {
    if (textCover !== '') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span
            style={{
              position: 'absolute',
              fontSize: '40px',
              color: '#fff',
              top: '40px',
            }}
          >
            {textCover}
          </span>
          <img alt={alt} src={img} />
        </div>
      );
    }
    return <img alt={alt} src={img} />;
  };
  return (
    <SCard
      bodyStyle={bodyStyle}
      bordered={bordered}
      cover={cover()}
      style={{ width, margin, backgroundColor: 'transparent' }}
    >
      <SMeta
        title={<div className={classNameTitle}>{title}</div>}
        description={<div className={classNameDescription}>{description}</div>}
      />
    </SCard>
  );
};

export default CCard;
