import React from 'react';

import { FormItemProps } from 'antd/lib/form';

import { FormItemCustomProps } from './interfaces';
import { SFormItem } from './styles';

const FormItem: React.FC<Partial<FormItemProps & FormItemCustomProps>> = ({
  children,
  ...rest
}) => {
  return (
    <SFormItem labelAlign="left" {...rest}>
      {children}
    </SFormItem>
  );
};

export default FormItem;
