import styled from 'styled-components';

export const Container = styled.header`
  background-color: #ffffff;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 4 !important;

  .menu-mobile {
    display: none;
  }
  .list-menu-mobile {
    display: none;
  }

  .anticon-menu {
    color: ${({ theme: { colors } }) => colors.default};
  }

  @media (max-width: 1200px) {
    .menu-language {
      display: none;
    }
    .menu-mobile {
      display: block;
    }
  }
  @media (max-width: 1060px) {
    .list-menu-content {
      display: none;
    }
    .list-menu-mobile {
      display: flex;
    }
  }
`;

export const SContainerMenuButton = styled.div`
  display: none;

  @media (max-width: 1200px) {
    display: block;
  }
`;

export const SMenu = styled.div`
  display: flex;
  align-items: center;
  li {
    display: flex;
    align-items: center;
    font-weight: 600;
    .divider {
      margin: 15px;
      height: 15px;
      border-left: 1px solid #a9a9a9 !important;
    }
    a {
      color: ${({ theme: { colors } }) => colors.default};
    }
    .active {
      color: #8eb159 !important;
    }
    a:active {
      color: #8eb159 !important;
    }
    &:hover {
      a {
        color: #8eb159;
      }
    }
  }
`;

export const SMenuMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  li {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin: 10px;
    .divider {
      margin: 0;
      height: 0;
      border: 0;
    }
    a {
      color: ${({ theme: { colors } }) => colors.default};
    }
    .active {
      color: #8eb159 !important;
    }
    a:active {
      color: #8eb159 !important;
    }
    &:hover {
      a {
        color: #8eb159;
      }
      color: #8eb159;
    }
  }
  .back {
    &:hover {
      color: #8eb159;
    }
    color: ${({ theme: { colors } }) => colors.default};
  }
  .menu-language-mobile {
    color: ${({ theme: { colors } }) => colors.default};
  }
  @media (max-width: 1060px) {
    display: flex;
  }
`;
