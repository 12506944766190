import React from 'react';
import { useTranslation } from 'react-i18next';

import logoHorizontalColor from '../../assets/img/logo.png';
import { LogoContainer } from './styles';

const Logo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LogoContainer>
      <img src={logoHorizontalColor} alt={t('home_site')} />
    </LogoContainer>
  );
};

export default Logo;
