import { Button } from 'antd';
import styled from 'styled-components';

import { ButtonCustomProps } from './interfaces';

export const SBtn = styled(Button)<ButtonCustomProps>`
  border-radius: 5px;
  font-size: 14px !important;
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-weight: 600;
  min-width: 188px;
  text-align: center;
  transition: background 0.2s;
  transition: font-size 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  ${({ size }) => {
    if (size === 'large') {
      return `padding: 6px 17px;
      min-width: 188px;`;
    }
    return `min-width: 131px;`;
  }};
  ${({ theme: { buttons, fonts }, status }) => {
    switch (status) {
      case 'primary':
        return `color: ${buttons.primary.color};
      background: ${buttons.primary.bg};
      border-color: ${buttons.primary.bg};`;
      case 'secondary':
        return `color: ${buttons.secondary.color};
      background: ${buttons.secondary.bg};
      border-color: ${buttons.secondary.bg};`;
      case 'outline':
        return `color: ${buttons.primaryOutline.color};
      background: ${buttons.primaryOutline.bg};
      border-color: ${buttons.primaryOutline.borderColor};`;
      case 'outline-white':
        return `color: #ffffff;
      background: transparent;
      border-color: #ffffff;
      border-width: 2px;
      border-radius: 5px;
      font-size: 18px !important;
      font-family: ${fonts.sitePrimary};
      font-weight: 500;`;
      case 'dark':
        return `color: ${buttons.primaryDark.color};
      background: ${buttons.primaryDark.bg};
      border-color: ${buttons.primaryDark.bg};`;
      case 'cta':
        return `color: ${buttons.cta.color};
      background: linear-gradient(to right, ${buttons.cta.bg.gradientStart}, ${buttons.cta.bg.gradientEnd});
      border: none;`;
      case 'ligth-danger':
        return `color: ${buttons.lightDanger.color};
        background: ${buttons.lightDanger.bg};
        border-color: ${buttons.lightDanger.bg};`;
      case 'ligth-green':
        return `color: ${buttons.lightGreen.color};
        background: ${buttons.lightGreen.bg};
        border-color: ${buttons.lightGreen.bg};`;
      default:
        return `color: ${buttons.primary.color};
        background: ${buttons.primary.bg};
        border-color: ${buttons.primary.bg}`;
    }
  }}

  & > svg {
    margin-right: 5px;
  }

  :hover,
  :visited {
    ${({ theme: { buttons, colors }, status }) => {
      switch (status) {
        case 'primary':
          return `color: ${buttons.primary.color};
          background: ${buttons.primary.bg};
          border-color: ${buttons.primary.bg};
          filter: grayscale(40%) drop-shadow(2px 2px 3px grey);`;
        case 'secondary':
          return `color: ${buttons.secondary.color};
          background: ${buttons.secondary.bg};
          border-color: ${buttons.secondary.bg};
          filter: grayscale(40%) drop-shadow(2px 2px 3px grey);`;
        case 'outline':
          return `color: ${buttons.primaryOutline.color};
          background: ${buttons.primaryOutline.bg};
          border-color: ${buttons.primaryOutline.borderColor};
          filter: grayscale(40%) drop-shadow(2px 2px 3px grey);`;
        case 'outline-white':
          return `color: ${colors.sitePrimary};
          background: #ffffff;
          border-color: #ffffff;
          filter: grayscale(40%) drop-shadow(2px 2px 3px grey);`;
        case 'dark':
          return `color: ${buttons.primaryDark.color};
            background: ${buttons.primaryDark.bg};
            border-color: ${buttons.primaryDark.bg};
            filter: grayscale(40%) drop-shadow(2px 2px 3px grey);`;
        case 'cta':
          return `color: ${buttons.cta.color};
          background: linear-gradient(to right, ${buttons.cta.hover.bg.gradientStart}, ${buttons.cta.hover.bg.gradientEnd});
          border: none;`;
        case 'ligth-danger':
          return `color: ${buttons.lightDanger.color};
          background: ${buttons.lightDanger.bg};
          border-color: ${buttons.lightDanger.bg};`;
        case 'ligth-green':
          return `color: ${buttons.lightGreen.color};
          background: ${buttons.lightGreen.bg};
          border-color: ${buttons.lightGreen.bg};`;
        default:
          return `color: inherit;
          background: inherit;
          border-color: inherit;
          filter: grayscale(40%) drop-shadow(2px 2px 3px grey);`;
      }
    }}
  }
`;

export default SBtn;
