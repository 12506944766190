import { Card } from 'antd';
import styled from 'styled-components';

const { Meta } = Card;

export const SCard = styled(Card)``;

export const SMeta = styled(Meta)`
  text-align: center;
  .title-tecnology {
    font-weight: bold;
    text-transform: uppercase;
    color: #697f9b;
  }
  .description-tecnology {
    color: #697f9b;
  }
  .title-benefits {
    font-weight: bold;
    color: #fff;
    white-space: normal;
    min-height: 60px;
  }
  .description-benefits {
    color: #fff;
  }
`;
