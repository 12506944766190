import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import routes from './routes';

const Routes: React.FC = () => (
  <Switch>
    <>
      {routes.map(route =>
        route.component ? (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ) : null,
      )}
    </>
  </Switch>
);

export default Routes;
