import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-scroll';

import {
  GlobalOutlined,
  MenuOutlined,
  LinkedinOutlined,
} from '@ant-design/icons';
import { Select, Divider } from 'antd';

import { ListMenu, ListMenuMobile, Logo } from '../../../components';
import { Container, SContainerMenuButton, SMenu, SMenuMobile } from './styles';

const Header: React.FC = () => {
  const { Option } = Select;
  const { t, i18n } = useTranslation();
  const changeLanguage = (language: string) => i18n.changeLanguage(language);
  const [menuMobile, setMenuMobile] = useState(false);

  function renderIconMobile() {
    return (
      <SContainerMenuButton>
        <MenuOutlined onClick={() => setMenuMobile(!menuMobile)} />
      </SContainerMenuButton>
    );
  }

  const linkedInUrl =
    'https://www.linkedin.com/company/agrodata-agroneg%C3%B3cios/';

  function renderMenuLanguage() {
    return (
      <ListMenu>
        <a target="_blank" href={linkedInUrl} rel="noreferrer">
          <LinkedinOutlined
            style={{
              color: '#697F9B',
              fontSize: 20,
              marginRight: 20,
              cursor: 'pointer',
            }}
          />
        </a>

        <li>
          <GlobalOutlined style={{ color: '#697F9B' }} />
          <Select
            defaultValue={i18n.language}
            onChange={changeLanguage}
            bordered={false}
            style={{ width: 113, color: '#697F9B' }}
          >
            <Option value="en">{t('header.lang.en')}</Option>
            <Option value="pt-BR">{t('header.lang.pt-BR')}</Option>
          </Select>
        </li>
      </ListMenu>
    );
  }

  function renderListMenuDesktop() {
    return (
      <>
        <li>
          <Link
            activeClass="active"
            className="technology"
            to="technology"
            spy
            duration={700}
            offset={-60}
          >
            {t('header.menu.technology')}
          </Link>
          <Divider type="vertical" className="divider" />
        </li>
        <li>
          <Link
            activeClass="active"
            className="advantage"
            to="advantage"
            spy
            duration={700}
            offset={-60}
          >
            {t('header.menu.advantage')}
          </Link>
          <Divider type="vertical" className="divider" />
        </li>
        <li>
          <Link
            activeClass="active"
            className="focusDataIntelligence"
            to="focusDataIntelligence"
            spy
            duration={700}
            offset={-60}
          >
            {t('header.menu.focusDataIntelligence')}
          </Link>
          <Divider type="vertical" className="divider" />
        </li>
        <li>
          <Link
            activeClass="active"
            className="requestDemo"
            to="requestDemo"
            spy
            duration={700}
            offset={-60}
          >
            {t('header.menu.requestDemo')}
          </Link>
          <Divider type="vertical" className="divider" />
        </li>
        <li>
          <a
            target="_blank"
            href={require('../../../assets/docs/seguranca.pdf')}
            rel="noreferrer"
          >
            {t('header.menu.security')}
          </a>
        </li>
      </>
    );
  }

  function renderMenuLanguageMobile() {
    return (
      <SMenuMobile className="menu-language-mobile">
        <li
          style={{ cursor: 'pointer' }}
          role="menuitem"
          onClick={() => changeLanguage('en')}
          onKeyPress={() => changeLanguage('en')}
        >
          {t('header.lang.en')}
        </li>
        <li
          style={{ cursor: 'pointer' }}
          role="menuitem"
          onClick={() => changeLanguage('pt-BR')}
          onKeyPress={() => changeLanguage('pt-BR')}
        >
          {t('header.lang.pt-BR')}
        </li>
      </SMenuMobile>
    );
  }

  function renderListMenuMobile() {
    return (
      <ListMenuMobile show={menuMobile}>
        <SMenuMobile className="list-menu-mobile">
          {renderListMenuDesktop()}
          <Divider type="horizontal" />
        </SMenuMobile>
        <SMenuMobile>
          {renderMenuLanguageMobile()}
          <Divider type="horizontal" />
          <li>
            <a target="_blank" href={linkedInUrl} rel="noreferrer">
              <div>LinkedIn</div>
            </a>
          </li>
          <Divider type="horizontal" />
          <li
            role="menuitem"
            onClick={() => setMenuMobile(!menuMobile)}
            onKeyPress={() => setMenuMobile(!menuMobile)}
            className="back"
            style={{ cursor: 'pointer' }}
          >
            <FaArrowLeft />
            <div style={{ marginLeft: 10 }}>{t('header.back')}</div>
          </li>
        </SMenuMobile>
      </ListMenuMobile>
    );
  }

  return (
    <>
      <Container>
        <Logo />
        <div className="list-menu-content">
          <ListMenu>
            <SMenu>{renderListMenuDesktop()}</SMenu>
          </ListMenu>
        </div>
        <div className="menu-language">{renderMenuLanguage()}</div>
        <div className="menu-mobile">{renderListMenuMobile()}</div>
        {renderIconMobile()}
      </Container>
    </>
  );
};

export default Header;
