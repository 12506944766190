import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  position: fixed;
  bottom: 30px;
  right: 30px;

  border-radius: 10px;

  padding: 10px 20px;

  color: ${({ theme: { colors } }) => colors.neutral};
  font-size: 14px;

  background: ${({ theme: { colors } }) => colors.default};

  z-index: 5;

  cursor: pointer;

  @media (max-width: 685px) {
    .text-button-whatsapp {
      display: none;
    }
  }
`;
