import React from 'react';

import { ListMenuProps } from './interfaces';
import { SListMenuMobile } from './styles';

const ListMenuMobile: React.FC<ListMenuProps> = ({ children, ...rest }) => {
  return <SListMenuMobile {...rest}>{children}</SListMenuMobile>;
};

export default ListMenuMobile;
