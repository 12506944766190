import React from 'react';

import Header from './Header';
import { Container, ContainerContent } from './styles';

const Page: React.FC = ({ children }) => {
  return (
    <>
      <Container>
        <Header />
        <ContainerContent>{children}</ContainerContent>
      </Container>
    </>
  );
};

export default Page;
