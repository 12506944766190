import { Form } from 'antd';
import styled from 'styled-components';

import { FormItemCustomProps } from './interfaces';

export const SFormItem = styled(Form.Item)<FormItemCustomProps>`
  &.ant-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ distance }) => (distance === 'small' ? '8px' : '24px')};
    padding-left: 10px;
    padding-right: 10px;

    & > .ant-form-item-label {
      display: block !important;
      color: ${({ theme }) => theme.colors.dark};
      font-family: ${({ theme }) => theme.fonts.primary};
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 3px;
      margin-left: 2px;
      min-height: 16px;
      padding-bottom: 0;
      text-align: left;
      width: 100%;

      & > label {
        height: auto;
        line-height: 1.5;
      }
    }

    & .ant-form-item-control-input {
      display: flex;
      align-items: baseline;
      width: 100%;
    }

    & > .ant-form-item-control {
      display: flex;
      justify-content: flex-start;
    }

    .ant-btn {
      margin-top: 15px;
    }
  }
`;

export default SFormItem;
