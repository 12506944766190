import React from 'react';

import { Input as AntInput } from 'antd';
import { InputProps as AntInputProps } from 'antd/lib/input';

const Input: React.FC<AntInputProps> = props => {
  return <AntInput allowClear size="middle" {...props} />;
};

export default Input;
