import styled from 'styled-components';

export const SListMenu = styled.ul`
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  display: flex;
`;
