import * as PageComponents from '../pages';

export interface Route {
  id?: string;
  path?: string;
  exact?: boolean;
  component?: React.ComponentType;
}

const routes: Route[] = [
  {
    path: '/',
    exact: false,
    component: PageComponents.Site,
  },
];

export default routes;
