import React from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import ptBRLocale from 'antd/es/locale/pt_BR';
import { createBrowserHistory } from 'history';
import { ValidateMessages } from 'rc-field-form/lib/interface';
import { ThemeProvider } from 'styled-components';

import Routes from './routes';
import './services/i18next';
import './assets/css/index.css';
import { messages } from './services/messages';
import GlobalStyle from './styles/global';
import { theme } from './styles/themes/default';

const App: React.FC = () => {
  const { i18n } = useTranslation();
  const history = createBrowserHistory();
  const validateMessages: ValidateMessages = messages(i18n.language);
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  return (
    <>
      <Router>
        <ThemeProvider theme={theme}>
          <ConfigProvider form={{ validateMessages }} locale={ptBRLocale}>
            <Routes />
          </ConfigProvider>
          <GlobalStyle />
        </ThemeProvider>
      </Router>
    </>
  );
};

export default App;
