import styled from 'styled-components';

import { ListMenuProps } from './interfaces';

const maxWidthMd = '1200px';

export const SListMenuMobile = styled.ul<ListMenuProps>`
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  @media (max-width: ${maxWidthMd}) {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    flex-direction: column;
    height: 100vh;
    width: ${({ show }) => (show ? '300px' : '0px')};
    overflow: hidden;
    transition: width 0.3s;
    white-space: nowrap;
    box-shadow: 1px 0px 10px #858585;
  }
`;
