import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    background-color: #ffffff;
    color: ${({ theme: { colors } }) => colors.dark};
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-size: 16px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;

    scrollbar-face-color: #697F9B;
    scrollbar-track-color: #C7CEE2;
    scrollbar-color: #697F9B #C7CEE2;
    scrollbar-width: thin;
    margin: 0;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 6px;

    &:horizontal {
      height: 6px;
    }
  }

  ::-webkit-scrollbar-track {
    background-color: #C7CEE2;
    width: 6px;

    &:horizontal {
      height: 6px;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: #697F9B;
    border-radius: 6px;
  }

  html, body {
    display: block;
    height: auto;
    max-height: 100%;
    width: 100%;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin-bottom: 0;
    line-height: 1.5;
  }

  .ant-pagination.ant-table-pagination {
    li > a {
      display: block;
      padding: 3px;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      background-color: #EDEDED;
      border: solid 1px #CFCECF;
      border-radius: 5px;
      height: 32px;
      width: 32px;

      a {
        color: #CFCECF;
      }
    }

    .ant-pagination-prev {
      margin-right: 6px;
    }

    .ant-pagination-next {
      margin-left: 6px;
    }

    .ant-pagination-item + .ant-pagination-item {
      margin-left: 6px;
    }

    .ant-pagination-item,
    .ant-pagination-item:not(.ant-pagination-item-active) {
      background-color: ${({ theme: { colors } }) => colors.secondary};
      border: solid 1px ${({ theme: { colors } }) => colors.secondary};
      border-radius: 5px;
      height: 32px;
      width: 32px;

      a {
        color: #ffffff;
      }
    }

    .ant-pagination-item-active {
      background-color: ${({ theme: { colors } }) => colors.darkSecondary};
      border: solid 1px ${({ theme: { colors } }) => colors.darkSecondary};
    }
  }

  .ant-table-wrapper {
    margin-top: 16px;
  }

  .ant-table-filter-dropdown {
    .ant-input-affix-wrapper {
      border-radius: 5px;
      margin-bottom: 10px;
    }
  }

  .ant-picker {
    border-radius: 5px;
    width: 100%;
  }

  .ant-radio-group {
    .ant-radio-button-wrapper:first-child {
      border-radius: 5px 0 0 5px;
    }

    .ant-radio-button-wrapper:last-child {
      border-radius: 0 5px 5px 0;
    }
  }

  .ant-input-number {
    display: block;
    width: 100%;
    border-radius: 5px;

    .ant-input-number-handler-up {
      border-top-right-radius: 5px;
    }

    .ant-input-number-handler-down {
      border-bottom-right-radius: 5px;
    }
  }

  .ant-form-item {
    &.ant-input-custom {
      .ant-input-affix-wrapper {
        max-height: initial !important;
      }
    }

    &.ant-form-item-with-help .ant-form-item-control {
      .ant-form-item-explain,
      .ant-form-item-extra {
        font-family: ${props => props.theme.fonts.primary} !important;
        font-size: 12px !important;
      }
    }

    &.ant-form-item-has-error .ant-form-item-control .ant-form-item-explain,
    &.ant-form-item-has-error .ant-form-item-control .ant-form-item-split {
      color: ${props => props.theme.colors.danger} !important;
    }

    &.ant-form-item-has-error .ant-input,
    &.ant-form-item-has-error .ant-input-affix-wrapper,
    &.ant-form-item-has-error .ant-input:hover,
    &.ant-form-item-has-error .ant-input-affix-wrapper:hover {
      border-color: ${props => props.theme.colors.danger} !important;
    }

    &.ant-form-item-has-success .ant-form-item-control .ant-form-item-explain,
    &.ant-form-item-has-success .ant-form-item-control .ant-form-item-split {
      color: ${props => props.theme.colors.success} !important;
    }

    &.ant-form-item-has-success .ant-input,
    &.ant-form-item-has-success .ant-input-affix-wrapper,
    &.ant-form-item-has-success .ant-input:hover,
    &.ant-form-item-has-success .ant-input-affix-wrapper:hover {
      border-color: ${props => props.theme.colors.success} !important;
    }

    &.ant-form-item-has-warning .ant-form-item-control .ant-form-item-explain,
    &.ant-form-item-has-warning .ant-form-item-control .ant-form-item-split {
      color: ${props => props.theme.colors.warning} !important;
    }

    &.ant-form-item-has-warning .ant-input,
    &.ant-form-item-has-warning .ant-input-affix-wrapper,
    &.ant-form-item-has-warning .ant-input:hover,
    &.ant-form-item-has-warning .ant-input-affix-wrapper:hover {
      border-color: ${props => props.theme.colors.warning} !important;
    }

    &.ant-form-item-has-warning .ant-form-item-control .ant-form-item-explain,
    &.ant-form-item-has-warning .ant-form-item-control .ant-form-item-split {
      color: ${props => props.theme.colors.warning} !important;
    }

    &.ant-form-item-has-warning .ant-input,
    &.ant-form-item-has-warning .ant-input-affix-wrapper,
    &.ant-form-item-has-warning .ant-input:hover,
    &.ant-form-item-has-warning .ant-input-affix-wrapper:hover {
      border-color: ${props => props.theme.colors.warning} !important;
    }

    .ant-upload.ant-upload-drag {
      border-radius: 5px;
    }

    .ant-input,
    .ant-input-affix-wrapper,
    .ant-select .ant-select-selector {
      background-color: ${props =>
    props.theme.customFields.defaultBackgroundColor};
      border-radius: 5px;
      color: ${props => props.theme.colors.primary};
      font-family: ${props => props.theme.fonts.primary};
      font-size: 14px;
      font-weight: 600;

      &:hover {
        border-color: ${props => props.theme.colors.darkGreyBlue};
        color: ${props => props.theme.colors.primary};
      }

      &:focus {
        border-color: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.primary};
      }

      &:disabled {
        background-color: #eef0f6;
        border-color: ${props => props.theme.colors.lightPrimary};
        color: ${props => props.theme.colors.darkGreyBlue};
      }

      &:not(.ant-form-item-with-help) .ant-input,
      &:not(.ant-form-item-with-help) .ant-input-password {
        border-color: ${props => props.theme.customFields.defaultBorderColor};
      }
    }

    .ant-input-suffix {
      .ant-input-clear-icon:hover {
        color: ${props => props.theme.colors.primary};
      }
    }
  }

  .modal-status {
    .modal-status-icon {
      padding: 20px;
    }

    &.modal-status-success .modal-status-icon {
      color: ${props => props.theme.colors.success};
    }

    &.modal-status-error .modal-status-icon {
      color: ${props => props.theme.colors.danger};
    }

    &.modal-status-info .modal-status-icon {
      color: ${props => props.theme.colors.info};
    }

    &.modal-status-warning .modal-status-icon {
      color: ${props => props.theme.colors.warning};
    }

    .ant-modal-content {
      border-radius: 5px;
      border: 1px solid #E3EAEA;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    }

    .ant-modal-confirm-title {
      color: ${props => props.theme.colors.darkPrimary};
      font-family: ${props => props.theme.fonts.primary};
      font-size: 18px;
      text-align: center;
      margin-bottom: 0;
    }

    .ant-modal-confirm-content {
      text-align: center;
      margin-bottom: 20px;

      .modal-status-subtitle {
        color: ${props => props.theme.colors.darkGreyBlue};
        font-family: ${props => props.theme.fonts.primary};
        font-size: 14px;
      }
    }

    .ant-modal-confirm-btns {
      display: flex;
      align-items: center;
      justify-content: center;
      float: none;
      margin-top: 30px;

      .ant-btn {
        border-radius: 20px !important;
        font-size: 15px !important;
        font-family: ${props => props.theme.fonts.primary} !important;
        font-weight: 500 !important;
      }
    }
  }

  .ant-tree {
    .ant-tree-title {
      color: ${({ theme: { colors } }) => colors.dark};
      font-family: ${({ theme: { fonts } }) => fonts.primary};
      font-size: 16px;
      font-weight: 400;
      word-break: break-word;
    }

    .anticon {
      color: ${({ theme: { colors } }) => colors.primary};
    }

    &.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
      .ant-tree-title {
        color: #ffffff;
      }

      .anticon {
        color: #ffffff;
      }
    }
  }

  .ant-btn {
    border-radius: 5px;
  }

  .ant-modal-content {
    border-radius: 5px;

    .ant-modal-header {
      border-radius: 5px 5px 0 0;
    }

    .ant-modal-footer {
      border-radius: 0 0 5px 5px;
    }
  }

  .no-padding-modal {
    .ant-modal-body {
      padding: 0;
    }
  }

`;
