import styled from 'styled-components';

import imgBannerSoja from '../../assets/img/site/banner/imagemBackground_soja.jpg';
import imgBackgroudBenefits from '../../assets/img/site/imgBackground_trigo1.png';

interface Text {
  align: 'center' | 'right' | 'left';
  weight: 'regular' | 'medium' | 'bold' | 'black';
  transform?: 'uppercase' | 'normal';
  color?: 'light' | 'blue' | 'dark';
  size?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  lineHeight?: string;
  textShadow?: string;
}

interface View {
  alignHorizontal: 'left' | 'center' | 'right';
  alignVertical: 'top' | 'center' | 'bottom';
  background?: 'blue' | 'white' | 'grey' | 'demonstration';
  height?: string;
  width?: string;
  container?: boolean;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  textShadow?: string;
}

export const STitle = styled.h2<Text>`
  display: block;
  font-family: ${({ theme: { fonts } }) => fonts.sitePrimary};
  line-height: ${({ lineHeight }) => lineHeight || '1.5'};
  font-size: ${({ size }) => size || '26px'};
  text-align: ${({ align }) => align};
  margin-top: ${({ marginTop }) => marginTop || '0'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};
  margin-left: ${({ marginLeft }) => marginLeft || '0'};
  margin-right: ${({ marginRight }) => marginRight || '0'};
  text-shadow: ${({ textShadow }) => textShadow || '0'};

  ${({ weight }) => {
    switch (weight) {
      case 'regular':
        return `font-weight: 400;`;
      case 'medium':
        return `font-weight: 500;`;
      case 'bold':
        return `font-weight: 700;`;
      case 'black':
        return `font-weight: 900;`;
      default:
        return `font-weight: 400;`;
    }
  }}

  ${({ transform }) => {
    if (!transform || transform === 'normal') {
      return `text-transform: initial;`;
    }
    return `text-transform: ${transform};`;
  }}

  ${({ color, theme: { colors } }) => {
    switch (color) {
      case 'dark':
        return `color: ${colors.sitePrimary};`;
      case 'blue':
        return `color: ${colors.siteSecondary};`;
      case 'light':
        return `color: #ffffff;`;
      default:
        return `color: ${colors.siteSecondary};`;
    }
  }}
`;

export const SText = styled.p<Text>`
  display: block;
  font-family: ${({ theme: { fonts } }) => fonts.sitePrimary};
  font-size: ${({ size }) => size || '16px'};
  line-height: ${({ lineHeight }) => lineHeight || '1.5'};
  text-align: ${({ align }) => align};
  margin-top: ${({ marginTop }) => marginTop || '0'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};
  margin-left: ${({ marginLeft }) => marginLeft || '0'};
  margin-right: ${({ marginRight }) => marginRight || '0'};

  ${({ weight }) => {
    switch (weight) {
      case 'regular':
        return `font-weight: 400;`;
      case 'medium':
        return `font-weight: 500;`;
      case 'bold':
        return `font-weight: 700;`;
      case 'black':
        return `font-weight: 900;`;
      default:
        return `font-weight: 400;`;
    }
  }}

  ${({ transform }) => {
    if (!transform || transform === 'normal') {
      return `text-transform: initial;`;
    }
    return `text-transform: ${transform};`;
  }}

${({ color, theme: { colors } }) => {
    switch (color) {
      case 'dark':
        return `color: ${colors.sitePrimary};`;
      case 'blue':
        return `color: ${colors.siteSecondary};`;
      case 'light':
        return `color: #ffffff;`;
      default:
        return `color: ${colors.sitePrimary};`;
    }
  }}
`;

export const SFlexView = styled.div<View>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${({ height }) => height || '100%'};
  max-width: ${({ width }) => width || '100%'};
  margin-top: ${({ marginTop }) => marginTop || '0'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};
  margin-left: ${({ marginLeft }) => marginLeft || '0'};
  margin-right: ${({ marginRight }) => marginRight || '0'};
  padding-top: ${({ paddingTop }) => paddingTop || '0'};
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '0'};
  padding-left: ${({ paddingLeft }) => paddingLeft || '0'};
  padding-right: ${({ paddingRight }) => paddingRight || '0'};

  ${({ background, theme: { colors } }) => {
    switch (background) {
      case 'demonstration':
        return `background-image: url('/assets/img/site/bg-demonstration.png');
        background-size: cover;
        background-position: center center;`;
      case 'white':
        return `background: #ffffff;`;
      case 'blue':
        return `background: ${colors.siteBgBlue};`;
      case 'grey':
        return `background: ${colors.siteBgGrey};`;
      default:
        return `background: transparent;`;
    }
  }}

  ${({ container }) => {
    if (container === true) {
      return `height: 100%; margin: auto;`;
    }
    return '';
  }}

  ${({ alignHorizontal, alignVertical }) => {
    let vertical = '';

    switch (alignVertical) {
      case 'top':
        vertical = `justify-content: flex-start;`;
        break;
      case 'center':
        vertical = `justify-content: center;`;
        break;
      case 'bottom':
        vertical = `justify-content: flex-end;`;
        break;
      default:
        vertical = `justify-content: flex-start;`;
    }

    switch (alignHorizontal) {
      case 'left':
        return `${vertical} align-items: flex-start;`;
      case 'center':
        return `${vertical} align-items: center;`;
      case 'right':
        return `${vertical} align-items: flex-end;`;
      default:
        return `${vertical} align-items: flex-start;`;
    }
  }}
`;

export const SInfoBanner = styled.div`
  // background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  // height: 40%;
  bottom: 0px;
  // position: absolute;
  margin-left: 10%;
`;

export const SListClients = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0;
`;

export const SClient = styled.li`
  margin-left: 67px;
  margin-right: 67px;
  margin-top: 30px;
  margin-bottom: 30px;
  min-height: 68px;
  width: 178px;
  max-width: 100%;
`;

export const SDemonstation = styled.div`
  color: ${({ theme: { colors } }) => colors.default};
  display: flex;
  flex-direction: column;
  align-items: center;

  .whatsapp {
    position: absolute;
    top: 0;
    right: 60px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
    padding: 15px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: center;
  }

  .demonstration-form {
    width: 50%;
    display: flex;
    flex-direction: column;

    .recaptcha {
      margin-left: 10px;
    }

    @media screen and (max-width: 1600px) {
      .sendButton {
        order: 5;
      }
    }
  }

  .ant-form-item-label label {
    color: ${({ theme: { colors } }) => colors.default};
  }
`;

export const SBannerOverlay = styled.div`
  // position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 0px 0% 88% 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FooterContainer = styled.div`
  background-color: #697f9b;
  text-align: center;

  p {
    color: #ffffff;
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 0;
  }
`;

export const STecnology = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
`;

export const SCards = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const SBenefits = styled.div`
  background-image: url(${imgBackgroudBenefits});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const SDataIntelligence = styled.div`
  background-color: ${({ theme: { colors } }) => colors.primary};
  display: flex;
  flex-direction: column;
  background-size: cover;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const SItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  width: 150px;
  margin: 30px 40px;
`;

export const SContent = styled.div`
  .area-carousel {
    background-image: url(${imgBannerSoja});
    background-size: cover;
    width: 100%;
    height: 800px;
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    justify-content: center;
    .area-video {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .video-frame {
        width: 90%;
        height: calc(57vw - 31.5vw);
      }
    }
  }
  @media (max-width: 1610px) {
    .advantage-cards {
      width: 1150px;
      .ant-card {
        width: 300px !important;
      }
    }
  }
  @media (max-width: 1385px) {
    .intelligence-cards {
      width: 1140px;
      .card-intelligence {
        width: 300px;
      }
    }
  }
  @media (max-width: 1330px) {
    .technology-cards {
      width: 950px;
    }
    .button-demonstration-header {
      margin-top: 40px;
    }
  }
  @media (max-width: 1260px) {
    .title-banner {
      font-size: 70px;
    }
  }

  @media (max-width: 1155px) {
    .advantage-cards {
      width: 860px;
      .ant-card {
        width: 350px !important;
      }
    }
    .intelligence-cards {
      width: 860px;
      .card-intelligence {
        width: 350px;
      }
    }
  }

  @media (max-width: 1080px) {
    .title-banner {
      font-size: 60px;
    }
  }

  @media (max-width: 955px) {
    .technology-cards {
      width: 100%;
    }
    .advantage-cards {
      width: 100%;
      .ant-card {
        width: 250px !important;
      }
    }
    .intelligence-cards {
      width: 100%;
      .card-intelligence {
        width: 250px;
      }
    }
  }

  @media (max-width: 930px) {
    .area-carousel {
      flex-direction: column;
      .area-banner {
        border-radius: 0px;
        .title-banner {
          text-align: center;
          margin: 0px;
        }
        .info-banner {
          margin: 0px;
          p {
            margin: 0px;
            margin-top: 15px;
            text-align: center;
          }
        }
        .button-demonstration-header {
          align-items: center;
        }
      }
    }
    .area-video {
      .video-frame {
        height: 90% !important;
      }
    }
  }

  @media (max-width: 880px) {
    .title-banner {
      font-size: 50px;
      margin-top: 50px;
    }
  }

  @media (max-width: 775px) {
    .ant-carousel {
      height: 300px;

      .slick-list {
        display: none;
      }
    }
    .title-banner {
      font-size: 40px;
      /* text-shadow: none;
      color: ${({ theme: { colors } }) => colors.default}; */
    }
    /* .info-banner {
      background-color: transparent;
      p {
        color: ${({ theme: { colors } }) => colors.default};
      }
    } */
  }
  @media (max-width: 580px) {
    .area-carousel {
      height: 700px;
      .ant-row {
        height: 430px;
      }
      .area-banner {
        padding: 10px;
        .button-demonstration-header {
          margin-top: 10px;
        }
      }
    }
  }
  @media (max-width: 528px) {
    .area-carousel {
      .area-banner {
        .title-banner {
          font-size: 30px;
        }
      }
    }
  }
`;
