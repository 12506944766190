import React from 'react';
import { useTranslation } from 'react-i18next';

import imgWhatsApp from '../../assets/img/site/whatsapp.svg';
import { Container } from './styles';

interface Props {
  onClick: (event: any) => void;
}

export const FloatWhatsApp = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Container onClick={onClick} role="button">
      <img src={imgWhatsApp} alt="WhatsApp" />
      <span className="text-button-whatsapp">{t('site.floatwhatsapp')}</span>
    </Container>
  );
};
