import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { Spin } from 'antd';

import App from './App';

ReactDOM.render(
  <Suspense
    fallback={
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '100%',
          height: 'calc(100vh - 60px)',
          width: '100%',
        }}
      >
        <Spin spinning />
      </div>
    }
  >
    <App />
  </Suspense>,
  document.getElementById('root'),
);
