import React, { useState } from 'react';
import { config } from 'react-spring';
import Carousel from 'react-spring-3d-carousel-2';

import img1 from '../../assets/img/site/carousel3d/img1.jpeg';
import img2 from '../../assets/img/site/carousel3d/img2.jpeg';
import img3 from '../../assets/img/site/carousel3d/img3.jpeg';
import img4 from '../../assets/img/site/carousel3d/img4.jpeg';
import { SCarousel } from './styles';

const Carousel3d: React.FC = () => {
  const [goToSlide, setGoToSlide] = useState(0);
  const slides = [
    {
      key: 1,
      content: <img src={img1} className="img-carousel-3d" alt="1" />,
    },
    {
      key: 2,
      content: <img src={img2} className="img-carousel-3d" alt="2" />,
    },
    {
      key: 3,
      content: <img src={img3} className="img-carousel-3d" alt="3" />,
    },
    {
      key: 4,
      content: <img src={img4} className="img-carousel-3d" alt="4" />,
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => setGoToSlide(index) };
  });

  return (
    <SCarousel>
      <Carousel
        slides={slides}
        goToSlide={goToSlide}
        offsetRadius={1}
        showNavigation={false}
        autoPlay
        interval={4}
        animationConfig={config.slow}
      />
    </SCarousel>
  );
};

export default Carousel3d;
