import styled from 'styled-components';

export const SIconContainer = styled.div`
  &.modal-status-icon {
    display: block;
    width: 100%;

    span {
      align-items: center;
      display: flex;
      font-size: 80px;
      justify-content: center;
    }
  }
`;
