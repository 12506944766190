/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';

import { Col, Form, notification } from 'antd';
import MaskedInput from 'antd-mask-input';
import moment from 'moment';

// import imgBannerMilho from '../../assets/img/site/banner/imagemBackground_milho.jpg';
// import imgBannerSoja from '../../assets/img/site/banner/imagemBackground_soja.jpg';
// import imgBannerAlgodao from '../../assets/img/site/banner/imgBackground_Algodao 1.jpg';
import imgCustomizavel from '../../assets/img/site/customizavel.png';
import imgCommercialStructuring from '../../assets/img/site/ImgEstuturacao 1.png';
// import imgWarrantyRegistration from '../../assets/img/site/ImgGarantias 1.png';
import imgRiskManagement from '../../assets/img/site/ImgGestaodeRiscos 1.png';
// import imgRemoteSensing from '../../assets/img/site/ImgSensoriamento 1.png';
import imgImplantacao from '../../assets/img/site/implantacao.png';
import imgIntegracao from '../../assets/img/site/integracao.png';
import imgInteracao from '../../assets/img/site/interacao.png';
import imgIntuitivo from '../../assets/img/site/intuitivo.png';
import imgLogoWithoutColor from '../../assets/img/site/logoWithoutColor.png';
import whiteLogo from '../../assets/img/site/MarcaAgroData_branca 1.png';
import imgSeguro from '../../assets/img/site/seguro.png';
import {
  Button,
  // Carousel,
  // CarouselSlide,
  FormItem,
  Input,
  ModalStatus,
  Row,
  CCard,
  Carousel3d,
} from '../../components';
import { FloatWhatsApp } from '../../components/FloatWhatsApp';
import api from '../../services/api';
import exceptionHandler from '../../services/exception';
import {
  SContent,
  SText,
  STitle,
  SFlexView,
  SDemonstation,
  SBannerOverlay,
  FooterContainer,
  SInfoBanner,
  STecnology,
  SCards,
  SBenefits,
  SDataIntelligence,
  SItems,
} from './styles';

const Site: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const whatsAppUrl =
    'https://api.whatsapp.com/send?phone=5541999256662&text=Ol%C3%A1%20Gustavo%2C%20gostaria%20de%20saber%20mais%20sobre%20os%20produtos%20da%20Agrodata.';

  const [isHuman, setIsHuman] = useState(false);

  useEffect(() => {
    configureAnchors({ offset: -60 });
  }, []);

  const handleSuccess = (data: { message: string }) =>
    ModalStatus({
      type: 'success',
      title: t('modal.success.title'),
      subTitle: t('modal.success.message'),
      onOk: () => form.resetFields(),
    });

  const handlerSendContact = (values: any) => {
    setLoading(true);

    if (!isHuman) {
      notification.open({
        message: t('alertTitleNotHuman'),
        description: t('alertDescriptionNotHuman'),
      });
      return;
    }
    api
      .post('contact-request', {
        ...values,
        language: i18n.language,
      })
      .then(response => {
        setLoading(false);
        handleSuccess(response.data);
      })
      .catch(err => {
        setLoading(false);
        exceptionHandler(err);
      });
  };

  const renderHeaderDemonstrationRequest = () => (
    <>
      <STitle
        align="left"
        weight="regular"
        transform="normal"
        // marginBottom="25px"
        color="light"
        size="80px"
        textShadow="#697F9B 0em 0em 0.2em"
        className="title-banner"
        lineHeight="1"
        marginLeft="10%"
      >
        {t('site.header.title')}
      </STitle>
      <SInfoBanner className="info-banner">
        <SText
          marginTop="20px"
          color="light"
          align="left"
          weight="regular"
          lineHeight="2"
          marginRight="130px"
        >
          {t('site.header.paragraph1')}
        </SText>
        <SText
          color="light"
          align="left"
          weight="regular"
          lineHeight="2"
          marginRight="150px"
        >
          {t('site.header.product')}
        </SText>
        <SFlexView
          alignHorizontal="left"
          alignVertical="top"
          marginTop="80px"
          className="button-demonstration-header"
        >
          <a href="/#requestDemo" title={t('site.header.button')}>
            <Button status="primary">{t('site.header.button')}</Button>
          </a>
        </SFlexView>
      </SInfoBanner>
    </>
  );

  return (
    <>
      <FloatWhatsApp onClick={() => window.open(whatsAppUrl, '_blank')} />
      <SContent>
        <div className="area-carousel">
          {/* <img
                style={{ width: '100%' }}
                src={imgBannerSoja}
                alt={t('site.header.banners.soja')}
              /> */}
          <SBannerOverlay className="area-banner">
            {renderHeaderDemonstrationRequest()}
          </SBannerOverlay>
          <div className="area-video">
            <iframe
              src="https://www.youtube.com/embed/bmXECClPA7E?autoplay=1&mute=1"
              title="video"
              className="video-frame"
            />
          </div>

          {/* <Row gutter={[26, 26]}>
            <Col
              className="desktop-layout"
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
            >
              <Carousel dots={false} autoplay draggable autoplaySpeed={5000}>
                <CarouselSlide>
                  <img
                    src={imgBannerAlgodao}
                    alt={t('site.header.banners.algodao')}
                  />
                </CarouselSlide>
                <CarouselSlide>
                  <img
                    src={imgBannerMilho}
                    alt={t('site.header.banners.milho')}
                  />
                </CarouselSlide>
                <CarouselSlide>
                  <img
                    src={imgBannerSoja}
                    alt={t('site.header.banners.soja')}
                  />
                </CarouselSlide>
              </Carousel>
              <SBannerOverlay>
                <SFlexView
                  alignHorizontal="center"
                  alignVertical="center"
                  container
                >
                  {renderHeaderDemonstrationRequest()}
                </SFlexView>
              </SBannerOverlay>
            </Col>
          </Row> */}
        </div>
        <Element name="technology">
          <STecnology>
            <STitle align="center" weight="medium" color="dark" size="36px">
              {t('site.tecnology.title')}
            </STitle>
            <SCards className="technology-cards">
              <CCard
                bordered
                margin="20px"
                img={imgCommercialStructuring}
                alt={t('imgCard1Comercial')}
                width="280px"
                title={t('site.tecnology.list1.card1.title')}
                description={t('site.tecnology.list1.card1.description')}
                classNameTitle="title-tecnology"
                classNameDescription="description-tecnology"
                bodyStyle={{ padding: '24px' }}
                textCover=""
              />
              {/* <CCard
                bordered
                margin="20px"
                img={imgWarrantyRegistration}
                alt={t('imgCard2Garantias')}
                width="280px"
                title={t('site.tecnology.list1.card2.title')}
                description={t('site.tecnology.list1.card2.description')}
                classNameTitle="title-tecnology"
                classNameDescription="description-tecnology"
                bodyStyle={{ padding: '24px' }}
                textCover=""
              /> */}
              {/* <CCard
                bordered
                margin="20px"
                img={imgRemoteSensing}
                alt={t('imgCard3Monitoramento')}
                width="280px"
                title={t('site.tecnology.list1.card3.title')}
                description={t('site.tecnology.list1.card3.description')}
                classNameTitle="title-tecnology"
                classNameDescription="description-tecnology"
                bodyStyle={{ padding: '24px' }}
                textCover=""
              /> */}
              <CCard
                bordered
                margin="20px"
                img={imgRiskManagement}
                alt={t('imgCard4Riscos')}
                width="280px"
                title={t('site.tecnology.list1.card4.title')}
                description={t('site.tecnology.list1.card4.description')}
                classNameTitle="title-tecnology"
                classNameDescription="description-tecnology"
                bodyStyle={{ padding: '24px' }}
                textCover=""
              />
            </SCards>
          </STecnology>
        </Element>

        <Element name="advantage">
          <SBenefits>
            <STitle
              size="36px"
              align="center"
              weight="medium"
              color="light"
              marginTop="20px"
              marginBottom="50px"
            >
              {t('site.benefits.title')}
            </STitle>
            <SCards className="advantage-cards">
              <CCard
                bordered={false}
                margin="0px 40px"
                img={imgLogoWithoutColor}
                alt={t('imgLogoWithoutColor')}
                width="187px"
                title={t('site.benefits.list.card1.title')}
                description={t('site.benefits.list.card1.description')}
                classNameTitle="title-benefits"
                classNameDescription="description-benefits"
                bodyStyle={{ padding: '24px 0px' }}
                textCover="01"
              />
              <CCard
                bordered={false}
                margin="0px 40px"
                img={imgLogoWithoutColor}
                alt={t('imgLogoWithoutColor')}
                width="187px"
                title={t('site.benefits.list.card2.title')}
                description={t('site.benefits.list.card2.description')}
                classNameTitle="title-benefits"
                classNameDescription="description-benefits"
                bodyStyle={{ padding: '24px 0px' }}
                textCover="02"
              />
              <CCard
                bordered={false}
                margin="0px 40px"
                img={imgLogoWithoutColor}
                alt={t('imgLogoWithoutColor')}
                width="187px"
                title={t('site.benefits.list.card3.title')}
                description={t('site.benefits.list.card3.description')}
                classNameTitle="title-benefits"
                classNameDescription="description-benefits"
                bodyStyle={{ padding: '24px 0px' }}
                textCover="03"
              />
              <CCard
                bordered={false}
                margin="0px 40px"
                img={imgLogoWithoutColor}
                alt={t('imgLogoWithoutColor')}
                width="187px"
                title={t('site.benefits.list.card4.title')}
                description={t('site.benefits.list.card4.description')}
                classNameTitle="title-benefits"
                classNameDescription="description-benefits"
                bodyStyle={{ padding: '24px 0px' }}
                textCover="04"
              />
              <CCard
                bordered={false}
                margin="0px 40px"
                img={imgLogoWithoutColor}
                alt={t('imgLogoWithoutColor')}
                width="187px"
                title={t('site.benefits.list.card5.title')}
                description={t('site.benefits.list.card5.description')}
                classNameTitle="title-benefits"
                classNameDescription="description-benefits"
                bodyStyle={{ padding: '24px 0px' }}
                textCover="05"
              />
              <CCard
                bordered={false}
                margin="0px 40px"
                img={imgLogoWithoutColor}
                alt={t('imgLogoWithoutColor')}
                width="187px"
                title={t('site.benefits.list.card6.title')}
                description={t('site.benefits.list.card6.description')}
                classNameTitle="title-benefits"
                classNameDescription="description-benefits"
                bodyStyle={{ padding: '24px 0px' }}
                textCover="06"
              />
            </SCards>
          </SBenefits>
        </Element>
        <Element name="focusDataIntelligence">
          <SDataIntelligence>
            <STitle
              size="36px"
              align="center"
              weight="medium"
              color="light"
              marginTop="20px"
              marginBottom="50px"
            >
              {t('site.data_intelligence.title')}
            </STitle>
            <Carousel3d />
            <SCards className="intelligence-cards">
              <SItems className="card-intelligence">
                <img
                  src={imgSeguro}
                  alt="seguro"
                  style={{ marginBottom: 10, height: '45px' }}
                />
                <div style={{ textAlign: 'center' }}>
                  {t('site.data_intelligence.list.item1')}
                </div>
              </SItems>
              <SItems className="card-intelligence">
                <img
                  src={imgCustomizavel}
                  alt="seguro"
                  style={{ marginBottom: 10, height: '45px' }}
                />
                <div style={{ textAlign: 'center' }}>
                  {t('site.data_intelligence.list.item2')}
                </div>
              </SItems>
              <SItems className="card-intelligence">
                <img
                  src={imgIntuitivo}
                  alt="seguro"
                  style={{ marginBottom: 10, height: '45px' }}
                />
                <div style={{ textAlign: 'center' }}>
                  {t('site.data_intelligence.list.item3')}
                </div>
              </SItems>
              <SItems className="card-intelligence">
                <img
                  src={imgIntegracao}
                  alt="seguro"
                  style={{ marginBottom: 10, height: '45px' }}
                />
                <div style={{ textAlign: 'center' }}>
                  {t('site.data_intelligence.list.item4')}
                </div>
              </SItems>
              <SItems className="card-intelligence">
                <img
                  src={imgImplantacao}
                  alt="seguro"
                  style={{ marginBottom: 10, height: '45px' }}
                />
                <div style={{ textAlign: 'center' }}>
                  {t('site.data_intelligence.list.item5')}
                </div>
              </SItems>
              <SItems className="card-intelligence">
                <img
                  src={imgInteracao}
                  alt="seguro"
                  style={{ marginBottom: 10, height: '45px' }}
                />
                <div style={{ textAlign: 'center' }}>
                  {t('site.data_intelligence.list.item6')}
                </div>
              </SItems>
            </SCards>
          </SDataIntelligence>
        </Element>

        <Element name="requestDemo">
          <ScrollableAnchor id="requestDemo">
            <SDemonstation>
              <STitle
                align="center"
                weight="medium"
                color="dark"
                size="36px"
                marginTop="20px"
                marginBottom="50px"
              >
                {t('site.demonstration.title')}
              </STitle>

              <Form
                form={form}
                className="demonstration-form"
                onFinish={handlerSendContact}
              >
                <Row gutter={[26, 26]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <FormItem
                      label={t('site.demonstration.fields.name')}
                      name="name"
                      rules={[{ required: true }]}
                    >
                      <Input size="large" />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={[26, 26]}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <FormItem
                      label={t('site.demonstration.fields.email')}
                      name="email"
                      rules={[{ required: true }]}
                    >
                      <Input size="large" type="email" />
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <FormItem
                      label={t('site.demonstration.fields.phone')}
                      name="phone"
                      rules={[{ required: true }]}
                    >
                      <MaskedInput
                        size="large"
                        name="phone"
                        mask="(11) 11111-1111"
                        placeholderChar=" "
                        placeholder="(__) _____-____"
                      />
                    </FormItem>
                  </Col>
                </Row>
                <div style={{ color: '#697f9b', textAlign: 'center' }}>
                  {t('messageSubmit.paragraph1')}{' '}
                  <a
                    style={{ color: '#8eb159' }}
                    target="_blank"
                    href={require('../../assets/docs/privacidade.pdf')}
                    rel="noreferrer"
                  >
                    {t('messageSubmit.link')}{' '}
                  </a>
                  {t('messageSubmit.paragraph2')}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 15,
                  }}
                >
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    onChange={() => setIsHuman(true)}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '20px',
                  }}
                >
                  <Button
                    status="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={!isHuman}
                  >
                    {t('site.demonstration.button')}
                  </Button>
                </div>
              </Form>
            </SDemonstation>
          </ScrollableAnchor>
        </Element>
      </SContent>
      <FooterContainer>
        <img
          src={whiteLogo}
          alt="white-logo"
          style={{ margin: 20, marginTop: 100 }}
        />
        <p style={{ margin: 20 }}>
          contato@agrodata.agr.br | 55 (41) 99925 6662
        </p>
        <p>
          {t('privacyPolicy.paragraph1')}{' '}
          <a
            style={{ color: '#A5CE37' }}
            target="_blank"
            href={require('../../assets/docs/privacidade.pdf')}
            rel="noreferrer"
          >
            {t('privacyPolicy.link')}
          </a>{' '}
          {t('privacyPolicy.paragraph2')}
        </p>
        <p style={{ margin: 20 }}>
          © {moment().utc().format('YYYY')} - {t('site.copyright')}
        </p>
        <div
          style={{ width: '100%', height: '80px', backgroundColor: '#526C8D' }}
        />
      </FooterContainer>
    </>
  );
};

export default Site;
