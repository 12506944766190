import { ModalStatus } from '../components';

export const exceptionHandler = (e: any, callback?: () => void) => {
  const { message } = e;
  let title = message;
  let errors = [];

  if (e.response !== undefined) {
    errors = message;
    title = e.response.data.message;

    if (e.response.data.errors !== undefined) {
      errors = e.response.data.errors;
    }
  }

  ModalStatus({
    type: 'error',
    title,
    subTitle: errors,
    onOk: callback,
  });
};

export default exceptionHandler;
