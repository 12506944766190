import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  borderRadius: '20px',

  shadow: 'rgba(0, 0, 0, 0.16)',

  dashboards: {
    lightPrimary: '#ebf7ff',
    softPrimary: '#D2EEFF',
  },

  colors: {
    default: '#697f9b',
    softPrimary: '#0C4477',
    primary: '#A5CE37',
    darkPrimary: '#27304A',
    lightPrimary: '#C7CEE2',
    secondary: '#01649D',
    darkSecondary: '#0C4477',
    lightSecondary: '#A0ABCC',
    darkGreyBlue: '#A0ABCC',
    greyBlue: '#032D5380',
    lightGreyBlue: '#00000029',
    sitePrimary: '#697f9b',
    siteSecondary: '#07406D',
    siteLightBlue: '#417DBB',
    siteBgBlue: 'rgba(0, 59, 128, 0.2)',
    siteBgGrey: 'rgba(200, 201, 203, 0.2)',
    dark: '#373531',
    gray: '#A9AAAA',
    danger: '#FF4D4F',
    lightDanger: '#FFC9CA',
    lightGreen: '#90ee90',
    warning: '#FAAD14',
    success: '#52C41A',
    ok: '#02B8AB',
    info: '#1890FF',
    neutral: '#FFFFFF',
    darkGrey: '#848484',
  },

  buttons: {
    primary: {
      color: '#ffffff',
      bg: '#A5CE37',
    },
    secondary: {
      color: '#514E49',
      bg: '#EDEDED',
    },
    primaryOutline: {
      color: '#01649D',
      bg: '#ffffff',
      borderColor: '#01649D',
    },
    primaryDark: {
      color: '#ffffff',
      bg: '#0C4477',
    },
    lightDanger: {
      color: '#A22224',
      bg: '#FFC9CA',
      borderColor: '#FFC9CA',
    },
    lightGreen: {
      color: '#377C45',
      bg: '#98EEC3',
      borderColor: '#98EEC3',
    },
    cta: {
      color: '#ffffff',
      bg: {
        gradientStart: '#01649D',
        gradientEnd: '#0C4477',
      },
      hover: {
        bg: {
          gradientStart: '#032D53',
          gradientEnd: '#27304A',
        },
      },
    },
  },

  fonts: {
    primary: "'Open Sans', sans-serif",
    secondary: "'Open Sans', sans-serif",
    sitePrimary: 'Roboto, sans-serif',
  },

  menu: {
    default: '#27304A',
    hover: '#01649D',
    active: '#01649D',
    activeBorderColor: '#01649D',
  },

  customFields: {
    defaultBackgroundColor: '#ffffff',
    defaultBorderColor: '#CFCECF',
    hoverBackgroundColor: '#ffffff',
    hoverBorderColor: '#01649D',
    activeBackgroundColor: '#01649D',
    activeBorderColor: '#01649D',
  },
};

export default theme;
export { theme };
