import styled, { css } from 'styled-components';

interface Content {
  padding?: string;
  margin?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
}

export const Container = styled.div`
  margin-top: 60px;
  height: calc(100vh - 60px);
`;

export const ContainerContent = styled.div<Content>`
  margin-top: ${({ marginTop }) => marginTop || '0'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};
  margin-left: ${({ marginLeft }) => marginLeft || '0'};
  margin-right: ${({ marginRight }) => marginRight || '0'};
  padding-top: ${({ paddingTop }) => paddingTop || '0'};
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '0'};
  padding-left: ${({ paddingLeft }) => paddingLeft || '0'};
  padding-right: ${({ paddingRight }) => paddingRight || '0'};
  ${({ margin }) => {
    if (margin)
      return css`
        margin: ${margin};
      `;
  }}
  ${({ padding }) => {
    if (padding)
      return css`
        padding: ${padding};
      `;
  }}
`;
